<template>
  <div>
    <b-overlay :show="show" opacity="0.95" variant="dark">
    <b-card>
      <h4>ตั้งค่า Messaging API</h4>
      <div class="ml-2">
        <h5>Basic settings</h5>
        <div class="ml-1">
          <b-row>
            <b-col cols="5" xl="5" md="12">
              <b-form-group label="Channel ID" label-cols-md="4">
                <b-form-input v-model="ch_id" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="5" xl="5" md="12">
              <b-form-group label="Channel secret" label-cols-md="4">
                <b-form-input v-model="ch_secret" />
              </b-form-group>
            </b-col>
          </b-row>
          <!-- <b-row>
            <b-col cols="5" xl="5" md="10">
              <b-form-group label="Privacy policy URL" label-cols-md="4">
                <b-form-input value="https://socio.co.th/privacy-statement" readonly />
              </b-form-group>
            </b-col>
            <b-col cols="2" xl="2" md="2">
              <b-button
                v-clipboard:copy="message"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="primary"
                size="sm"
              >
                คัดคอก
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="5" xl="5" md="12">
              <b-form-group label="Terms of use URL" label-cols-md="4">
                <b-form-input value="https://socio.co.th/privacy-statement" readonly />
              </b-form-group>
            </b-col>
            <b-col cols="2" xl="2" md="2">
              <b-button
                v-clipboard:copy="message3"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="primary"
                size="sm"
              >
                คัดคอก
              </b-button>
            </b-col>
          </b-row> -->
        </div>
      </div>
      <div class="ml-2">
        <h5>Messaging API</h5>
        <div class="ml-1">
          <b-row>
            <b-col cols="5" xl="5" md="12">
              <b-form-group label="Channel access token" label-cols-md="4">
                <b-form-input v-model="ch_token" />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </div>

      <h4>ตั้งค่า LINE Login</h4>
      <div class="ml-2">
        <h5>Basic settings</h5>
        <div class="ml-1">
          <b-row>
            <b-col cols="5" xl="5" md="12">
              <b-form-group label="Channel ID" label-cols-md="4">
                <b-form-input v-model="loginch_id" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="5" xl="5" md="12">
              <b-form-group label="Channel secret" label-cols-md="4">
                <b-form-input v-model="loginch_secret" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="5" xl="5" md="10">
              <b-form-group label="Privacy policy URL" label-cols-md="4">
                <b-form-input :value="message22" readonly />
              </b-form-group>
            </b-col>
            <b-col cols="2" xl="2" md="2">
              <b-button
                v-clipboard:copy="message22"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="primary"
                size="sm"
              >
                คัดคอก
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="5" xl="5" md="12">
              <b-form-group label="Terms of use URL" label-cols-md="4">
                <b-form-input :value="message23" readonly />
              </b-form-group>
            </b-col>
            <b-col cols="2" xl="2" md="2">
              <b-button
                v-clipboard:copy="message23"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="primary"
                size="sm"
              >
                คัดคอก
              </b-button>
            </b-col>
          </b-row>
        </div>
        <h5>LINE Login</h5>
        <div class="ml-1">
          <b-row>
            <b-col cols="5" xl="5" md="12">
              <b-form-group label=" Callback URL" label-cols-md="4">
                <b-form-input value="https://wifi.socio.co.th/authLiff" readonly />
              </b-form-group>
            </b-col>
            <b-col cols="2" xl="2" md="2">
              <b-button
                v-clipboard:copy="message"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="primary"
                size="sm"
              >
                คัดคอก
              </b-button>
            </b-col>
          </b-row>
        </div>
        <h5>LIFF</h5>
        <div class="ml-1">
          <b-row>
            <b-col cols="5" xl="5" md="12">
              <b-form-group
                label="Endpoint URL
        "
                label-cols-md="4"
              >
                <b-form-input value="https://wifi.socio.co.th/authLiff" readonly />
              </b-form-group>
            </b-col>
            <b-col cols="2" xl="2" md="2">
              <b-button
                v-clipboard:copy="message"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="primary"
                size="sm"
              >
                คัดคอก
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="5" xl="5" md="12">
              <b-form-group label="LIFF ID" label-cols-md="4">
                <b-form-input v-model="liff_url" />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </div>
      <b-row>
        <!-- submit and reset -->
        <b-col offset-md="4">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click="loadOA()"
          >
            บันทึก
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <template v-slot:overlay>
      <div class="overlay-alert" id="overlay-alert">กำลังบันทึกข้อมูล</div>
    </template>
  </b-overlay>
    <!-- 
    <b-row>
      <b-col cols="8" xl="8" md="6" class="mb-2">
        <h5 class="mb-0">LINE Login</h5>
        <small class="text-muted">Enter LINE Login info.</small><br />
      </b-col>
      <b-col md="12">
        <b-form-group label="LINE Login Channel ID" label-for="loginch_id">
          <validation-provider #default="{ errors }" name="loginch_id" rules="required">
            <b-form-input
              id="loginch_id"
              v-model="loginch_id"
              :state="errors.length > 0 ? false : null"
              placeholder="LINE login channel ID"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col md="12">
        <b-form-group label="LINE Login Channel Secret" label-for="loginch_secret">
          <validation-provider
            #default="{ errors }"
            name="loginch_secret"
            rules="required"
          >
            <b-form-input
              id="loginch_secret"
              v-model="loginch_secret"
              :state="errors.length > 0 ? false : null"
              placeholder="LINE login channel Secret"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="12">
        <hr />
        Callback URL
        <br />
        <b-button
          v-clipboard:copy="message"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="primary"
          size="sm"
        >
          คัดคอก
        </b-button>
        <code>https://wifi.socio.co.th/authLiff</code>
        &nbsp;&nbsp;&nbsp;
        <b-button
          v-clipboard:copy="message2"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="primary"
          size="sm"
        >
          คัดคอก
        </b-button>
        <code>https://wifi.socio.co.th/authLiff</code>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="8" xl="8" md="6" class="mb-2">
        <h5 class="mb-0">LINE liffl</h5>
        <br />
        <b-button
          v-clipboard:copy="message"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="primary"
          size="sm"
        >
          คัดคอก
        </b-button>
        <code>https://wifi.socio.co.th/authLiff</code>
        <br />
        <small class="text-muted">Enter LINE liff</small>
      </b-col>
      <b-col md="12">
        <b-form-group>
          <validation-provider #default="{ errors }" name="liff_url" rules="required">
            <b-form-input
              id="liff_url"
              v-model="liff_url"
              :state="errors.length > 0 ? false : null"
              placeholder="LINE Liff"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="8" xl="8" md="6" class="mb-2">
        <h5 class="mb-0">Messaging API</h5>
        <small class="text-muted">Enter Messaging API Info.</small>
      </b-col>
      <b-col md="12">
        <b-form-group label="Channel ID" label-for="ch_id">
          <validation-provider #default="{ errors }" name="Channel ID" rules="required">
            <b-form-input
              id="ch_id"
              v-model="ch_id"
              placeholder="Channel ID"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group label="Channel Secret" label-for="ch_secret">
          <validation-provider
            #default="{ errors }"
            name="Channel Secret"
            rules="required"
          >
            <b-form-input
              id="ch_secret"
              v-model="ch_secret"
              :state="errors.length > 0 ? false : null"
              placeholder="Channel Secret"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col md="12">
        <b-form-group label="Channel Token" label-for="ch_token">
          <validation-provider
            #default="{ errors }"
            name="Channel Token"
            rules="required"
          >
            <b-form-input
              id="ch_token"
              v-model="ch_token"
              :state="errors.length > 0 ? false : null"
              placeholder="Channel Token"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="8" xl="8" md="6" class="mb-2">
        <h5 class="mb-0">Bot info</h5>
        <b-avatar size="70px" :src="resp.pictureUrl" />

        <h1>{{ resp.displayName }}</h1>
        <h2>{{ resp.basicId }}</h2>
      </b-col>
    </b-row> -->
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BAvatar,
  BButton,
  BCard,
  BOverlay,
} from "bootstrap-vue";
import { required, email } from "@validations";
import api from "@/api";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BCard,
    BOverlay,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BAvatar,
    BButton,
  },
  data() {
    return {
      show: false,
      name: "",
      ch_id: "",
      ch_secret: "",
      ch_token: "",
      loginch_id: "",
      loginch_secret: "",
      liff_url: "",
      
      resp: [],
      message: "https://wifi.socio.co.th/authLiff",
      message2: "https://captive.socio.co.th/auth",
      message3: "https://socio.co.th/privacy-statement/",
      message23: "https://api.socio.co.th/consent?id="+localStorage.getItem("store_id")+"&type=2",
      message22: "https://api.socio.co.th/consent?id="+localStorage.getItem("store_id")+"&type=1",
    };
  },
  created() {
    this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", true);
  },
  destroyed() {
    this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", this.menuHidden);
  },
  methods: {
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Failed to copy texts!",
          icon: "BellIcon",
        },
      });
    },
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Text copied",
          icon: "BellIcon",
        },
      });
    },
    //*******insert Line OA********
    formSubmitted() {
      var userData = localStorage.getItem("userData");
      if (this.resp) {
        const params = {
          user_main: userData.user_main,
          ch_token: this.ch_token,
          ch_id: this.ch_id,
          ch_secret: this.ch_secret,
          loginch_id: this.loginch_id,
          loginch_secret: this.loginch_secret,
          liff_url: this.liff_url,
          oadata: this.resp,
          store_id: localStorage.getItem("store_id"),
        };
        var access_token = localStorage.getItem("accessToken");
        api
          .post("lineoa/add", params, {
            headers: {
              Authorization: "Bearer " + access_token,
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            this.show = false
            console.log(response.data);
            this.$swal({
              title: "เพิ่ม LINE OA สำเร็จ",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.$router.push("/store/detail");
          })
          .catch(function (error) {
            console.log(error.toJSON());
          });

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Form Submitted",
            icon: "EditIcon",
            variant: "success",
          },
        });
      }
    },

    loadOA() {
      this.show = true
   
      if (
        this.ch_token &&
        this.ch_id &&
        this.ch_secret &&
        this.loginch_id &&
        this.loginch_secret &&
        this.liff_url
      ) {
        const params = {
          ch_token: this.ch_token,
          ch_id: this.ch_id,
          ch_secret: this.ch_secret,
          loginch_id: this.loginch_id,
          loginch_secret: this.loginch_secret,
          liff_url: this.liff_url,
        };
        var access_token = localStorage.getItem("accessToken");
        api
          .post("lineoa/check", params, {
            headers: {
              Authorization: "Bearer " + access_token,
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            console.log("lineoa/check ", response.data);
            this.resp = response.data;
            if (response.data.userId) {
           
              this.formSubmitted();
            }
          })
          .catch(function (error) {
            this.show = false
            console.log(error.toJSON());
          });
      }
    },
  },
};
</script>
